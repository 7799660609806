import Excel from "exceljs";
import { saveAs } from 'file-saver';

class XlsxExportEngine {
    saveToXlsx(fileName, data) {
        let workbook = this.createAndFillWorkBook(data);
        this.invokeSaveFileDialog(fileName, workbook);
    }
    invokeSaveFileDialog(fileName, workbook) {
        workbook.xlsx.writeBuffer()
        .then((buf) => {
          let blob = new Blob([buf], {type: "octet/stream"});
          saveAs(blob, fileName);
        });
    }

    createAndFillWorkBook(data) {
        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet("Sheet1");
        
        worksheet.columns = Object.keys(data[0]).map(k => {
            return {
                header: k, 
                key: k
            }}
        );

        data.forEach((row) => worksheet.addRow(row));
        
        return workbook;
    }

}

export default XlsxExportEngine;